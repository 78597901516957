import React from 'react';
import StaticResourceText from './../Format/static-resource-text';
import configJson from '../../Config.json'

const ContactForm = ({resources}) => {

    return (
        <>

          
         <div className="form__wrapper">
                <div className="contactus-wrapper">
                    <a href="\contact-us" className="form-small-trigger__icon ContactusIcon" rel="noopener noreferrer">
                        <img src={configJson.S3ImageURL+"/static/SVG/contact.svg"} alt="Click here to view contact form" />
                        <div className="form-small-trigger__text">
                            <h3 className="color-white"><StaticResourceText resources={resources} reskey={'CU-POPUP-TITLE'}></StaticResourceText></h3>
                        </div>
                    </a>

                </div>
            </div>

        </>
    )
}
export default ContactForm