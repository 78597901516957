import React from "react";
import configJson from '../../Config.json'

const InfographicsTemplate = ({ infographics }) => {
  const parse = require("html-react-parser");
  return (
    <div className="bg-white" async>
      <section className="container reimagine">
        <div
          className="holder-carousel info_carousel"
          style={{ marginTop: "50px" }}
        >
          <div className="arrow__container">
            <button className="swiper__prev">
              <img
src={configJson.S3ImageURL+"/static/SVG/arrow_burgundy.svg"}                role="button"
                alt="Left arrow"
                className="left"
                srcSet=""
                loading="lazy"
              />
            </button>
            <div className="line"></div>
            <button className="swiper__next">
              <img
       src={configJson.S3ImageURL+"/static/SVG/arrow_burgundy.svg"}
                role="button"
                alt="right arrow"
                srcSet=""
                loading="lazy"
              />
            </button>
          </div>

          <div className="swiper infoSwiper">
            <div className="swiper-wrapper">
              {infographics &&
                infographics.map((info) => {
                  /* NOSONAR */

                  return (
                    <div className="swiper-slide" key={info.title} async>
                      <div className="carousel">
                        <div className="carousel__img bg-white">
                          <img src={info.image} alt={info.title} />
                        </div>
                        <div className="carousel__content">
                          <h3 className="color-black carousel__title--2">
                            {info.number}
                          </h3>
                          <h4 className="color-primary carousel__title--1">
                            {info.shortDesc && parse(info.shortDesc)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default InfographicsTemplate;
