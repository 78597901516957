import React from "react";
import Anchor from "../Icons/Anchor";
import Date from "../Format/Date";
import CommonObj from "../CommonObj";
import { useState, useEffect } from "react";
import configJson from '../../Config.json';

const CaseStudyListTemplate = ({ heading, type, bottomspace, searchText, sort, filter,currentPage, setCurrentPage }) => {
  const [blogs, setblogs] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const pageSize = configJson.PageSize;

  const fetchData = async (event) => {
    setLoading(true);
    setblogs([]);
    setLoading(true);
    let data = {
      "contentType": CommonObj.ContentType.CaseStudies,
      "search": searchText,
      "sort": sort,
      "pageName": CommonObj.PageNameList.CaseStudies,
      "pageSize": pageSize,
      "PageNo": currentPage,
      "Filter": filter
    }
    let res = await fetch(CommonObj.Endpoints.ContentListEndpoint, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    let Data = await res.json();
    setblogs(Data.contents);
    setTotalRow(Data?.contents[0]?.totalRow);
    setLoading(false);
  };

  useEffect(() => {
    const debounceFetch = setTimeout(() => {
      fetchData();
    }, 300); // Debounce time in milliseconds

    return () => clearTimeout(debounceFetch);
  }, [searchText, sort, filter, currentPage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const totalPages = Math.ceil(totalRow / pageSize);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const capitalizeFirstLetter = (sentence) => {
    if (!sentence) return '';
    const lowerCased = sentence.toLowerCase();
    return lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
  };

  const renderPagination = () => {
    const maxVisiblePages = isMobile ? 5 : totalPages; 
    const halfVisible = Math.floor(maxVisiblePages / 2);
    let startPage = Math.max(1, currentPage - halfVisible);
    let endPage = Math.min(totalPages, currentPage + halfVisible);

    if (endPage - startPage < maxVisiblePages - 1) {
      if (startPage === 1) {
        endPage = Math.min(maxVisiblePages, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - maxVisiblePages + 1);
      }
    }

    return (
      <ul>
        {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
          <li
            className={(startPage + index === currentPage) ? "active" : ""}
            key={startPage + index}
            onClick={() => handlePageChange(startPage + index)}
          >
            {startPage + index}
          </li>
        ))}
      </ul>
    );
  };

  if (loading) {
    return <div className="loader"><img src="/assets/img/loading.gif" /></div>;
  }

  const parse = require("html-react-parser");
  if (blogs != null && blogs.length > 0) {

    return (
      <>
        <div className={(bottomspace ? "container-mb" : "") + " bg-gray mat-10"}>
          <section className="container casestudies">
            <div className={"holder-thumbnails "}>
              {blogs.length &&
                blogs.map((casestudies) => {
                  return (
                    <div className="carousel" key={casestudies.title}>
                      <div className="carousel__img">

                        <img
                          src={casestudies.image ? casestudies.image : configJson.S3ImageURL+"/static/Images/Case_Study_GBS_PS1_thumb.png"}
                          alt="{casestudies.title}"
                          srcSet=""
                        />
                      </div>
                      <div className="carousel__content flow">
                        <h3 title ={casestudies?.title.includes("<")? parse(casestudies.title)?.props?.children : casestudies.title} className="color-primary carousel__title--1">
                        {casestudies.title && parse(casestudies.title.slice(0, 43) + (casestudies.title.length > 43 ? '...' : ''))}
                        </h3>
                        <p title={casestudies && casestudies.shortDesc ? parse(capitalizeFirstLetter(casestudies.shortDesc)) : ''} className="color-black carousel__title--2">
                        {casestudies.shortDesc && parse(capitalizeFirstLetter(casestudies.shortDesc.slice(0, 50) + (casestudies.shortDesc.length > 50 ? '...' : '')))}
                        </p>

                        <Anchor
                          className="read-more"
                          anchorText="VIEW SUCCESS STORY"
                          icon={true}
                          isPinkbtn={true}
                          link={"/casestudies" + casestudies.anchorlink}
                        ></Anchor>
                      </div>
                    </div>
                  );
                })}
              {/* </div> */}
            </div>
            <div className="pagination_outer">
            <div className="pagination">
              <div className={"pagi_left_icon " + (currentPage === 1 ? "hide" : "")} onClick={handlePreviousPage} >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 6L9 12L15 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              {renderPagination()}
              <div className={"pagi_right_icon " + (currentPage === totalPages ? "hide" : "")} onClick={handleNextPage}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 18L15 12L9 6" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
          </div>
          </section>
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <span className="not-found">No Records Found</span>
      </>
    )
  }
}
export default CaseStudyListTemplate;
