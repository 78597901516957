import React from 'react';
import Plus from '../Icons/Plus';
import configJson from '../../Config.json';

const BranchTemplate = ({ branches }) => { /* NOSONAR */
    const parse = require('html-react-parser');
    return (
        <>
            <div className="location__popup">
                <div className="location__content">
                    <div className="mobile__close"><Plus></Plus></div>
                    {branches.length && branches.map((branch, i) => {
                        return <div className="location__details" data-target={branch.title} key={branch.location}>
                            <h3 className="location__heading">{branch.location}</h3>
                            <p>{branch.address && parse(branch.address)}</p>
                            <p>
                                <a href={"tel:" + branch.phone} rel="noopener noreferrer"> <img src={configJson.S3ImageURL+"/static/SVG/phone.svg"} alt="Phone" />{branch.phone} </a> </p>
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}
export default BranchTemplate