import React from "react";
import Anchor from "../Icons/Anchor";
import Date from "../Format/Date";
import StaticResourceText from "../Format/static-resource-text";
import configJson from '../../Config.json'

const ThoughtPapersTemplate = ({thoughtpapers,resources, heading, type, bottomspace }) => {
  /* NOSONAR */
  const parse = require("html-react-parser");
  return (
    <div className={(bottomspace ? "container-mb" : "") + " bg-white mat-10"}>
      <section className="container thought_papers">
        <div className="container__title">
        <h2>

        <StaticResourceText
              resources={resources}
              reskey={"THOUGHTPAPERS-TITLE"}
            ></StaticResourceText>   
            </h2>     
            </div>

        <div className={"holder-thumbnails " + type}>
          {thoughtpapers.length &&
            thoughtpapers.map((thoughtpaper) => {
              return (
                <div className="carousel" key={thoughtpaper.title}>
                  <div className="carousel__img">
                    <img
                      src={thoughtpaper.image || configJson.S3ImageURL+"/static/Images/placeholder_img.jpg"}
                      alt="{thoughtpaper.title}"
                      srcSet=""
                    />
                  </div>
                  <div className="carousel__content flow">
                    <h3 className="color-black carousel__title--2">
                      {thoughtpaper.title &&
                        parse(thoughtpaper.title)}
                    </h3>
                    <p className="carousel__title--3">
                      {thoughtpaper.date && (
                        <Date dateString={thoughtpaper.date}></Date>
                      )}
                    </p>

                    <Anchor
                      className="read-more"
                      anchorText="READ MORE"
                      icon={true}
                      isPinkbtn={true}
                      link={"/thoughtPapers" + thoughtpaper.anchorlink}
                    ></Anchor>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </div>
  );
};
export default ThoughtPapersTemplate;
