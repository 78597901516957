import React from 'react';
import Parallax from '../Common/Parallax';
import configJson from '../../Config.json'

const PeoplePanelTemplate = ({ panels, classes }) => {

    const parse = require('html-react-parser');

    return (
        <React.Fragment>
            <div className="full-width people pannel template">
                {panels && panels.map((panel) => { /* NOSONAR */
                    return <>
                        {panel.direction === "Left" &&
                            <React.Fragment key={panel.id}>
                                <div className="col2 p_left">
                                    <div>
                                        <h3>{panel.title && parse(panel.title)}</h3>
                                        {panel.shortDesc && parse(panel.shortDesc)}
                                    </div>
                                </div>
                                <div className={"col2 imgbg1 "+classes}>
                                    <Parallax pagePanelBgImage1={panel.image ? panel.image : ''}></Parallax>
                                    <div className="bg bg1">
                                    </div>
                                    <div className="colimg_container">
                                    <img src={configJson.S3ImageURL+"/static/Images/col_imgoverlay.svg"} alt="vectorimage" />
                                    </div>
                                </div>
                            </React.Fragment>}
                        {panel.direction === "Right" &&
                            <React.Fragment key={panel.id}>
                                <div className="col2 imgbg2">
                                    <Parallax pagePanelBgImage2={panel.image ? panel.image : ''}></Parallax>
                                    <div className="bg bg2">
                                    </div>
                                    <div className="colimg_container flexend">
                                    <img className=" flip" src={configJson.S3ImageURL+"/static/Images/col_imgoverlay.svg"} alt="vectorimage" />
                                    </div>
                                </div>

                                <div className={"col2 p_right "+classes}>
                                    <div>
                                        <h3>{panel.title && parse(panel.title)}</h3>
                                        {panel.shortDesc && parse(panel.shortDesc)}
                                    </div>
                                </div>
                            </React.Fragment>}
                    </>
                })}
            </div>
        </React.Fragment>
    )

}

export default PeoplePanelTemplate