import React from "react";
import StaticResourceText from "../Format/static-resource-text";

const TestimonialExcerptSection = ({ testimonials, resources, bgGray }) => {
  const parse = require("html-react-parser");
  return (
    <div className={"expert "+(bgGray ? "bg_gray_light" : "")}>
      {testimonials &&
        testimonials.map((testimonial) => {
          /* NOSONAR */
          return (
            <div className={"expert__container "+(bgGray ? "pd-mob-pad" : "")} key={testimonial.title}>
              <div className="expert__vedge bg-gray"></div>
              <div className="expert__img">
                <img src={testimonial.image} alt="Author image" width="260px" loading="lazy"/>
              </div>
              <div className="expert__content flow">
                <h2>
                  <StaticResourceText
                    resources={resources}
                    reskey={"PANEL-EXPERTSPEAK-TITLE"}
                  ></StaticResourceText>
                </h2>
                <p className="">{parse(testimonial.shortDesc)}</p>
                <p className="mb0 color-primary">
                  - {parse(testimonial.title)}, {parse(testimonial.designation)}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default TestimonialExcerptSection;
