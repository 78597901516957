import React, { useState ,useEffect} from 'react';
import CommonObj from '../CommonObj';
import heroCarousel from '../../assets/js/Components/heroCarousel';
import carousel from '../../assets/js/Components/carousel';
import faq  from '../../assets/js/Components/faq';
import { mobileMenu,navigationInit} from '../../assets/js/Components/navigation';
import hidePreLoad from '../../assets/js/Components/hidePreLoad';
import parallax ,{ scrollRefresh }from '../../assets/js/Components/parallax';
import resizeWindow from '../../assets/js/Components/resizeWindow';
import videoInit from '../../assets/js/Components/video';
import formInit from '../../assets/js/Components/formInit';
import Parallax from '../Common/Parallax';
import Hero from '../Common/Hero';
import IndexSection1 from '../index/index-section1';
import IndexPanel from '../index/index-panel';
import IndexVideo from '../index/index-video';
import TestimonialExcerptSection from '../Common/TestimonialExcerptSection';
import TestimonialTemplate from '../Common/TestimonialTemplate';
import BlogTemplate  from '../Common/blog-template';
import FAQTemplate from '../Common/faq-template';
import PageNotFound from '../Common/PageNotFound';
import { Helmet } from 'react-helmet';
import Section from '../Section/Section';
import PreLoad from '../Common/preload';
import CommonFunc from '../../assets/js/Components/CommonFunc';
import { scrollMemory } from '../../assets/js/Components/parallax';
import BreadCrumb from '../Common/breadcrumb';


const Home = (props)  =>{
  console.log("renders: ");
    const [page, setPage] = useState();
    const [loading, setLoading] = useState(true); 
    const fetchData = async () => 
    {
        let pageName = CommonObj.PageNameList.Home;
        console.log(JSON.stringify(pageName));
        console.log(CommonObj.Endpoints.GetPage);
        let res = await fetch(CommonObj.Endpoints.GetPage, {
            method: 'POST',
            body: JSON.stringify( pageName),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        let page = await res.json()
        setPage(page);
        CommonFunc(page);
    }
    function PageScroller()
  {
    const timer = setTimeout(() => 
    {
      setLoading(false);
    }, 3000);
  }
    
    useEffect(() => 
    {
        // fetch call used to be here
          fetchData();
          PageScroller();
    },[]);

    useEffect(() => {
      if (page != null) 
      {
        console.log("home Load");
        heroCarousel();
        carousel();
        formInit();
        parallax();
        scrollRefresh();
        videoInit();
        faq();
        return () => 
        {
            scrollMemory(); 
        };
      }
      window.scrollTo(0, 0);
      hidePreLoad();
      resizeWindow();
    });


    let faqSchemaJson = {};
    let faqSchemaMainEntity = [];

    if (page != null && page != undefined)  
    {
        let heroCarouselList = page.contents.filter(x => x.type == CommonObj.ContentType.Carousels);
        let infographics = page.contents.filter(x => x.type == CommonObj.ContentType.Infographics);
        let panels = page.contents.filter(x => x.type == CommonObj.ContentType.Panels);
        let blogs = page.contents.filter(x => x.type == CommonObj.ContentType.Blogs);
        let casestudies = page.contents.filter(x => x.type == CommonObj.ContentType.CaseStudies);
        let faqs = page.contents.filter(x => x.type == CommonObj.ContentType.FAQS);
        let testimonials = page.contents.filter(x => x.type == CommonObj.ContentType.Testimonials);
        let videos = page.contents.filter(x => x.type == CommonObj.ContentType.Videos);
        const parse = require('html-react-parser');
        {
          !!faqs.length &&
            faqs.map((faq) => {
                if (faq.shortDesc != null) {
                  /* NOSONAR */
                  faqSchemaMainEntity.push(
                    `{'@type':'Question',
                       'name':'${faq.title.replace(/<([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/g,"")}',
                       'acceptedAnswer':{'@type':'Answer',
                                         'text':'${faq.shortDesc.replace(/<([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/g,"")}'} }`
                  );
                } else {
                  /* NOSONAR */
                  faqSchemaMainEntity.push(
                    `{'@type':'Question',
                      'name':'${faq.title.replace(/<([a-zA-Z][a-zA-Z0-9]*)\b[^>]*>/g,"")}',
                      'acceptedAnswer':{'@type':'Answer','text':''} }`
                  );
                }
              });
          }
          faqSchemaJson = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [],
          };
          faqSchemaJson.mainEntity.push(faqSchemaMainEntity);
          function addFAQSchemaMarkup() {
            return {
              __html: JSON.stringify(faqSchemaJson),
            };
          }
        return (
          <>
           {loading ?  (<PreLoad></PreLoad>) : (
          <Section PageName={page.name}>
          <Helmet>
              <title>{page.title}</title>
              <meta name="description" content={page.metaDescription} />
              <meta name="keywords" content={page.metaKeywords} />
              {/* <link rel="canonical" href={process.env.baseDomainUrl + asPath} /> */}
              {!!faqs.length && (
                  <script nonce="GoCMSWebNAUSGHDV" type="application/ld+json" dangerouslySetInnerHTML={addFAQSchemaMarkup()} key="faqschema-jsonld"/>
               )}
           </Helmet>
           {/* <PreLoad></PreLoad>  */}
            <Parallax pageheroimage={page.image ? page.image : ''}></Parallax>
            <Hero landingpage={true} carouselList={heroCarouselList} resources={props.resources}></Hero>
                                    <BreadCrumb page={page?.name}/>

            <div className="bg-white">
              <section className="container reimagine">
                {page.longDesc && parse(page.longDesc)}
                <IndexSection1 infographics={infographics}></IndexSection1>
              </section>
            </div>
            {!!videos.length && <IndexVideo videos={videos}></IndexVideo>}
            <IndexPanel panels={panels}></IndexPanel>
            {!!testimonials.length && <TestimonialExcerptSection testimonials={testimonials} resources={props.resources}></TestimonialExcerptSection>}
            {!!casestudies.length && <TestimonialTemplate testimonials={casestudies} resources={props.resources}></TestimonialTemplate>}

            {!!blogs.length && <BlogTemplate bgblue={false} alignleft={false} blogs={blogs} notopspace={true} resources={props.resources}></BlogTemplate>}
            {!!faqs.length && <FAQTemplate faqs={faqs} notopspace={true} resources={props.resources}></FAQTemplate>}
                       </Section>
            )}
          </>
        )
      }
      else {
        return(
          <>
          <PreLoad></PreLoad>
          {/* {loading ?  (<PreLoad></PreLoad>) : (
              <PageNotFound></PageNotFound>
          )} */}
          </>
          )
      }
}
export default Home;